import React from 'react'
import { getCookie, setCookie } from 'cookies-next'

import { useApiUserGroups } from 'api/competency-mapping/useAPICompetencyMapping'
import { useAPIGetUserGroupsByUserId } from 'api/user-groups/useAPIUserGroups'
import { UJIKOM_ENABLED_GROUP } from 'app/Homepage/constants/UjikomData'
import { useAuth } from 'utils/auth'
import { useUserGroupsContext } from 'utils/providers/useUserGroupsContext'
import { PPG_DALJAB_ENABLED_GROUP } from 'app/Homepage/constants'

export interface IUserGroupData {
  groupList?: any
  isLoading: boolean
  hasAccess: boolean
}

type IUserGroups = {
  id: string
  name: string
  type: string
}

export type UserGroupsCookieContent = {
  groups?: IUserGroups[]
  userId?: string
}

const useUserGroup = (
  groupName: string,
  enabled: boolean,
  defaultValue: boolean,
  queryOptions?: any
): IUserGroupData => {
  const { data, status, isLoading } = useApiUserGroups(groupName, {
    enabled,
    ...queryOptions,
  })

  // If not enabled, return deafult value
  if (enabled === false) return { isLoading: false, hasAccess: defaultValue }

  // Else check from the returned data from the API
  return {
    groupList: data?.data ?? [],
    isLoading,
    hasAccess:
      status === 'success' &&
      data?.data?.some((group) => group?.name === groupName),
  }
}

const useUserGroupSjt = (): IUserGroupData => {
  const { isLoading, isUserHasGroup } = useUserGroupsContext()
  return {
    isLoading: isLoading,
    hasAccess: isUserHasGroup(UJIKOM_ENABLED_GROUP),
  }
}

const useUserGroupPpgDaljab = (): IUserGroupData => {
  const { isLoading, isUserHasGroup } = useUserGroupsContext()

  return {
    isLoading: isLoading,
    hasAccess: isUserHasGroup(PPG_DALJAB_ENABLED_GROUP),
  }
}

type TUserGroupByUserIdProps = {
  enabled?: boolean
}
const useUserGroupByUserId = ({ enabled = true }: TUserGroupByUserIdProps) => {
  const { session } = useAuth()
  const storedUserGroups = React.useMemo(() => {
    const userGroupsInCookie = getCookie('USER_GROUPS')
    return userGroupsInCookie ? JSON.parse(userGroupsInCookie as string) : null
  }, [])

  const setStoredUserGroups = (data: UserGroupsCookieContent) =>
    setCookie('USER_GROUPS', data, {
      maxAge: 60 * 60, // 1 hour
    })

  const { data, isLoading, isSuccess, refetch } = useAPIGetUserGroupsByUserId(
    session?.user?.id,
    {
      enabled: enabled && !storedUserGroups?.groups,
      onSuccess: (data) => {
        setStoredUserGroups({
          groups: data?.data,
          userId: session?.user?.id,
        })
      },
    }
  )

  let hasGroups = isSuccess || storedUserGroups?.groups?.length > 0

  const currentUserGroups = React.useMemo(
    () =>
      hasGroups
        ? (data?.data ?? storedUserGroups?.groups ?? []).map(({ name }) => name)
        : [],
    [storedUserGroups, hasGroups, data?.data]
  )

  const userHasOneOfRoles = (roles: string[]) => {
    return roles.some((role) => currentUserGroups.includes(role?.toLowerCase()))
  }

  return {
    isLoading,
    userHasOneOfRoles,
    error: !isSuccess && !storedUserGroups?.groups,
    groups: storedUserGroups?.groups,
    refetch,
  }
}

export {
  useUserGroup,
  useUserGroupSjt,
  useUserGroupPpgDaljab,
  useUserGroupByUserId,
}
