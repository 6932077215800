import { useQuery } from 'react-query'
import axios from 'axios'
import { captureException } from '@sentry/nextjs'

export enum CPKvKeys {
  FeatureFlag = 'feature_flag',
  IKMEntryPointContent = 'ikm_entry_point_content',
  UjikomMainContent = 'ujikom_main_content',
  UjikomContactContent = 'ujikom_contact_content',
  UjikomPanduanContent = 'ujikom_panduan_content',
  UjikomPactContent = 'ujikom_pakta_content',
  UjikomPeriodContent = 'ujikom_period_content',
  UjikomEntryPointContent = 'ujikom_entry_point_content',
  IKMClosingPeriod = 'ikm_closing_period',
  RPDToggle = 'rpd_toggle',
  AsesmenRefleksiPeriod = 'asesmen_refleksi_period',
  AsesmenRefleksiHomeEntry = 'asesmen_refleksi_home_entry',
}

export let keyValueUrl = !!process.env.NEXT_PUBLIC_CP_CDN
  ? process.env.NEXT_PUBLIC_CP_CDN + '/key-value'
  : 'https://cdn.guru-cp-fe.belajar.id/key-value'

function fetchKeyValue<T>(key: string): Promise<{ data: T }> {
  return typeof key === 'undefined'
    ? Promise.reject(new Error('invalid Key'))
    : axios.get(`${keyValueUrl}/${key}.json`)
}

export function useGetCPKeyPairValue<T = any>(
  key: string,
  enabled: boolean = true,
  queryConfig = {}
) {
  return useQuery([keyValueUrl, key], () => fetchKeyValue<T>(key), {
    ...queryConfig,
    onError: (error) => captureException(error),
    enabled,
  })
}
