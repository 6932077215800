import { useMutationApi, useQueryApi } from 'utils/api'

import type { CommonResponse } from 'types/api'
import type { UseQueryOptions } from 'react-query'
import type {
  IAssessmentStatusResult,
  IUjikomCertificate,
  IUjikomQuestionData,
  IUjikomQuestionMeta,
  IUjikomResult,
  IUjikomSubmitQuestions,
  TQuestionSessionState,
} from 'types/content-platform'
import type { IUserGroups } from 'types/user'
import type { CustomError } from 'utils/customError'
import type { ReflectionResultData } from 'types/competency-profile'
import type { CommonErrorResponse } from 'api/microlearning/types'

interface ResponseBodyQuestionSession {
  data: IUjikomQuestionData
  metadata: IUjikomQuestionMeta
}

interface RequestBodyQuestionAnswer {
  questionSessionId: string
  state: TQuestionSessionState
  questions: IUjikomSubmitQuestions[]
}
interface RequestBodyGenerateCertificate {}

const BASE_PATH = '/guru/content-platform/v1alpha1/competency-mapping'

export function useApiCompetencyMappingQuestionSession(
  assessmentType: string,
  userId: string,
  enabled: boolean,
  errorHandler?: (error?: any) => void
) {
  return useQueryApi<ResponseBodyQuestionSession, CustomError>(
    `${BASE_PATH}/question-session/${assessmentType}`,
    {
      axiosConfig: {
        params: {
          user_id: userId,
        },
      },
      queryConfig: {
        useErrorBoundary: false,
        enabled,
      },
      errorHandlerFn: (_status, _path, error) => {
        errorHandler?.(error)
      },
    }
  )
}

export function useApiCompetencyMappingResult(
  assessmentType: string,
  enabled: boolean,
  refetchInterval: ((data) => number | false) | false = false,
  errorHandler?: (error?: any) => void
) {
  return useQueryApi<IUjikomResult>(`${BASE_PATH}/result/${assessmentType}`, {
    queryConfig: {
      enabled,
      refetchInterval,
      useErrorBoundary: false,
    },
    errorHandlerFn: (_status, _path, error) => {
      errorHandler?.(error)
    },
  })
}

export function useApiCompetencyMappingAssessmentStatus(
  assessmentType: string,
  opts?: UseQueryOptions<any>
) {
  return useQueryApi<IAssessmentStatusResult>(
    `${BASE_PATH}/assessment-status`,
    {
      axiosConfig: {
        params: {
          assessmentType,
        },
      },
      queryConfig: {
        ...opts,
      },
    }
  )
}

export function useMutateApiCompetencyMappingQuestionAnswer(
  assessmentType: string
) {
  return useMutationApi<any, CustomError, RequestBodyQuestionAnswer>(
    `${BASE_PATH}/question-answers/${assessmentType}`,
    {
      axiosConfig: { method: 'PATCH' },
    }
  )
}

export function useApiUserGroups(groupName?: string, opts?: any) {
  return useQueryApi<IUserGroups>('/guru/teachers/v1alpha2/groups', {
    axiosConfig: {
      params: {
        groupName,
        limit: opts?.limit || 10,
      },
    },
    queryConfig: {
      retry: false,
      useErrorBoundary: false,
      ...opts,
    },
  })
}

export function useApiCompetencyMappingCertificate(
  assessmentType: string,
  enabled: boolean,
  opts?: UseQueryOptions<any>
) {
  return useQueryApi<IUjikomCertificate>(
    `${BASE_PATH}/certificate/${assessmentType}`,
    {
      queryConfig: {
        useErrorBoundary: false,
        enabled,
        ...opts,
      },
    }
  )
}

export function useMutateApiCompetencyMappingCertificate(
  assessmentType: string
) {
  return useMutationApi<any, Error, RequestBodyGenerateCertificate>(
    `${BASE_PATH}/certificate/${assessmentType}`,
    {
      axiosConfig: { method: 'POST' },
    }
  )
}

export function useApiCompetencyMappingReflectionResult(
  opts?: UseQueryOptions<
    CommonResponse<ReflectionResultData[]>,
    CommonErrorResponse
  >
) {
  return useQueryApi<
    CommonResponse<ReflectionResultData[]>,
    CommonErrorResponse
  >(`${BASE_PATH}/reflection-result`, {
    queryConfig: {
      staleTime: 30 * 60 * 1000, // stale after 30 min, force to refetch upon mounting
      ...opts,
    },
  })
}
