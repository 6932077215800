import { useMediaQuery } from 'react-responsive'

export const useMediaQueryHelper = () => {
  const isDesktop = useMediaQuery({
    // ref: https://tailwindcss.com/docs/responsive-design we use lg as desktop
    query: '(min-width:1024px)',
  })

  return {
    isDesktop,
  }
}
