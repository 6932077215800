import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import type {
  IAsesmenRefleksiPeriodData,
  ICompareAsesmenRefleksiPeriodResponse,
} from 'types/competency-profile'
import type {
  ICompareUjikomPeriodResponse,
  IUjikomPeriodData,
} from 'types/content-platform'

export const checkDateTimeServer = async () => {
  const currentDateTimeServer = dayjs().format()

  return currentDateTimeServer
}

export const useCompareUjikomPeriod = (
  currentDateTimeServer: string,
  ujikomPeriodData: IUjikomPeriodData
): ICompareUjikomPeriodResponse => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(isBetween)
  let currentDateTimeServerObj = dayjs(currentDateTimeServer)
  let openAtObj = dayjs(ujikomPeriodData.openAt)
  let examStartAtObj = dayjs(ujikomPeriodData.exam.startAt)
  let examEndAtObj = dayjs(ujikomPeriodData.exam.endAt)
  let certificateGeneratedAtObj = dayjs(ujikomPeriodData.certificateGenerateAt)

  const [ujikomPeriodSkip] = useLocalStorage(
    'ujikom-period-skip',
    null,
    (text: string) => text
  )
  if (ujikomPeriodSkip)
    return {
      isBeforeEntryOpen: ujikomPeriodSkip === 'isBeforeEntryOpen',
      isEntryOpen:
        ujikomPeriodSkip === 'isEntryOpen' ||
        ujikomPeriodSkip === 'isInExamPeriod' ||
        ujikomPeriodSkip === 'isAfterExamPeriod',
      isInExamPeriod: ujikomPeriodSkip === 'isInExamPeriod',
      isAfterExamPeriod: ujikomPeriodSkip === 'isAfterExamPeriod',
      isBeforeCertificateGenerated:
        ujikomPeriodSkip === 'isBeforeCertificateGenerated',
    }

  let isPeriod = {
    isBeforeEntryOpen: currentDateTimeServerObj.isBefore(openAtObj),
    isEntryOpen: currentDateTimeServerObj.isAfter(openAtObj),
    isInExamPeriod: currentDateTimeServerObj.isBetween(
      examStartAtObj,
      examEndAtObj
    ),
    isAfterExamPeriod: currentDateTimeServerObj.isAfter(examEndAtObj),
    isBeforeCertificateGenerated: currentDateTimeServerObj.isBefore(
      certificateGeneratedAtObj
    ),
  }

  return isPeriod
}

export const useCompareAsesmenRefleksiPeriod = (
  currentDateTimeServer: string,
  asesmenRefleksiPeriodData: IAsesmenRefleksiPeriodData
): ICompareAsesmenRefleksiPeriodResponse => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(isBetween)
  const currentDateTimeServerObj = dayjs(currentDateTimeServer)
  const [asesmenRefleksiPeriodSkip] = useLocalStorage(
    'asesmen-refleksi-period-skip',
    null,
    (text: string) => text
  )

  if (asesmenRefleksiPeriodSkip) {
    return {
      deadlineDate: '',
      isEntryOpen: asesmenRefleksiPeriodSkip === 'isEntryOpen',
      isLoading: false,
    }
  }

  if (!asesmenRefleksiPeriodData) {
    return {
      deadlineDate: '',
      isEntryOpen: false,
      isLoading: true,
    }
  }

  const openAtObj = dayjs(asesmenRefleksiPeriodData.openAt)
  const closeAtObj = dayjs(asesmenRefleksiPeriodData.closeAt)

  let isPeriod = {
    isEntryOpen: currentDateTimeServerObj.isBetween(openAtObj, closeAtObj),
    isLoading: false,
    deadlineDate: asesmenRefleksiPeriodData.deadlineDate,
  }

  return isPeriod
}
