import { Spinner } from '@wartek-id/spinner'

import LoadingState from 'app/PemetaanKompetensiPage/components/LoadingState'
import {
  CPKvKeys,
  useGetCPKeyPairValue,
} from 'api/content-platform/useAPIKeyValue'
import PeriodGuardChecker from './PeriodGuardChecker'

import type { IUjikomPeriodData } from 'types/content-platform'

const PeriodGuard = ({
  currentDateTimeServer,
  children,
  fullPageLoading = false,
}) => {
  const {
    isLoading: isLoadingUjikomPeriod,
    data: ujikomPeriodData,
    isSuccess: isSuccessPeriod,
  } = useGetCPKeyPairValue<IUjikomPeriodData>(CPKvKeys.UjikomPeriodContent)

  const isDonePeriod = !isLoadingUjikomPeriod && isSuccessPeriod

  if (isLoadingUjikomPeriod)
    return fullPageLoading ? (
      <LoadingState title="Halaman sedang dimuat..." />
    ) : (
      <Spinner />
    )

  if (isDonePeriod)
    return (
      <PeriodGuardChecker
        currentDateTimeServer={currentDateTimeServer}
        ujikomPeriodData={ujikomPeriodData.data}
      >
        {children}
      </PeriodGuardChecker>
    )
  return null
}

export default PeriodGuard
