import { useRouter } from 'next/router'

import { historyUtils } from '@wartek-id/fe-toolbox'

import { useCompareUjikomPeriod } from '../utils'

const PeriodGuardChecker = ({
  currentDateTimeServer,
  ujikomPeriodData,
  children,
}) => {
  const router = useRouter()

  let IsPeriod = useCompareUjikomPeriod(currentDateTimeServer, ujikomPeriodData)

  if (IsPeriod) {
    if (router.pathname === '/' && IsPeriod.isBeforeEntryOpen) {
      return null
    }
    if (router.pathname === '/home' && IsPeriod.isBeforeEntryOpen) {
      return null
    }
    if (
      !IsPeriod.isInExamPeriod &&
      ![
        '/pemetaan-kompetensi',
        '/pemetaan-kompetensi/sertifikat',
        '/home',
        '/',
      ].includes(router.pathname)
    ) {
      router.replace('/pemetaan-kompetensi')
      return null
    }
    if (IsPeriod.isBeforeEntryOpen) {
      historyUtils.backToApp(() => router.push('/home'))
      return null
    }
    if (
      IsPeriod.isBeforeCertificateGenerated &&
      router.pathname === '/pemetaan-kompetensi/sertifikat'
    ) {
      router.replace('/pemetaan-kompetensi')
      return null
    }
    return children
  }
}

export default PeriodGuardChecker
