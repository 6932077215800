import { Text } from '@wartek-id/text'
import type { SpinnerProps } from '@wartek-id/spinner'

import { LoadingState as BaseLoadingState } from 'components/LoadingState'

import type { FC } from 'react'

interface LoadingStateProps {
  title?: string
  description?: string
  spinnerSize?: SpinnerProps['size']
}

const LoadingState: FC<LoadingStateProps> = ({
  title,
  description = 'Mohon tunggu sebentar, ya.',
  spinnerSize = 'lg',
}) => {
  return (
    <BaseLoadingState
      blockClassName="flex-col"
      spinnerSize={spinnerSize}
      disableWrapper
    >
      <div className="space-y-2 mt-5 flex flex-col items-center">
        {title && <Text variant="heading-md">{title}</Text>}
        {description && <Text variant="body">{description}</Text>}
      </div>
    </BaseLoadingState>
  )
}
export default LoadingState
